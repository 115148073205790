<template>
  <el-dialog
    :title="this.dialogText.dialogTitle"
    :visible.sync="dialogVisible"
    width="680px"
    center
    @open="openDialog()"
    @close="resetForm"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <!-- 表单区域 -->
    <el-form
      :rules="dialogFormRules"
      ref="dialogFormRef"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-input
        v-model="dialogForm.name"
        placeholder="请输入姓名"
        prefix-icon="el-icon-search"
        clearable
        @input="openDialog()"
      ></el-input>

      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        ref="multipleTable"
        @selection-change="selectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="55"
        ></el-table-column>
        <el-table-column
          v-if="dialogText.dialogTitle === '添加指定考生'"
          align="center"
          prop="name"
          label="姓名"
        ></el-table-column>
        <el-table-column
          v-else
          align="center"
          prop="teacherName"
          label="姓名"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="phone"
          label="手机号"
        ></el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="dialogForm.pageNum"
        :page-sizes="[5, 10 , 20, 30, 40, 100, 200, 1000]"
        :pager-count="5"
        :page-size="dialogForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        small
      >
      </el-pagination>
    </el-form>

    <!-- 底部按钮区域 -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="cancelBtn">取 消</el-button>
      <el-button size="small" type="primary" @click="submitForm" :disabled="isDisable">
        {{ this.dialogText.btnText + " (" + this.selectCount + ")" }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  unassignedStudent,
  unassignedTeacher,
  addGroupStudent,
  addGroupTeacher,
  addGroupBatch,
} from "r/admin/group";
export default {
  name: "GroupChangeDialog",
  props: {
    dialogVisibleFu: Boolean,
    dialogText: Object,
    dialogForm: Object,
    examId: String,
  },
  data() {
    return {
      dialogVisible: this.dialogVisibleFu, // 控制dialog弹框显示与隐藏
      // dialogForm: this.dialogForm, // 数据对象
      dialogFormRules: {}, // 表单验证规则
      selectData: [], //表格中选中项的数据集合
      selectCount: 0, // 表格中选中项的数量
      ids: [], // 选中的列的id数组集合
      tableData: [], // 表格数据列表
      total: 0, // 总页数
      isDisable: false, // 提交按钮是否可点击（防重复点击）
    };
  },
  created() {
    this.openDialog();
  },
  methods: {
    openDialog() {
      if (this.dialogText.dialogTitle === "批量新增") {
        this.getUnassignedTeacher();
      } else if (this.dialogText.dialogTitle === "添加监考老师") {
        this.getUnassignedTeacher();
      } else if (this.dialogText.dialogTitle === "添加指定考生") {
        this.getUnassignedStudent();
      }
    },
    // 获取未分配学生
    async getUnassignedStudent() {
      const { data: res } = await unassignedStudent(this.dialogForm);
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.tableData = res.data.records;
      this.total = res.data.total;
    },

    // 获取未分配老师
    async getUnassignedTeacher() {
      const { data: res } = await unassignedTeacher(this.dialogForm);
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.tableData = res.data.records;
      this.total = res.data.total;
    },

    // 控制每页显示几条数据
    handleSizeChange(newSize) {
      this.dialogForm.pageSize = newSize;
      this.openDialog();
    },
    // 控制显示第几页
    handleCurrentChange(newNum) {
      this.dialogForm.pageNum = newNum;
      this.openDialog();
    },

    // 重置表单
    resetForm() {
      this.$refs.dialogFormRef.resetFields();
      this.selectCount = 0;
      this.selectData = [];
      this.tableData = [];
      this.$refs.multipleTable.clearSelection();
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.isDisable = false;
      this.resetForm();
      this.$emit("closeDialog", this.dialogVisible);
    },

    // 选中状态改变
    selectionChange(val) {
      // val 指选中的列的数组集合
      // 清空ids数据
      this.selectData = val;
      this.ids = [];
      this.selectCount = val.length;
      val.forEach((item) => {
        // console.log(item);
        this.ids.push(item.id);
      });
    },

    // 确定,提交表单
    async submitForm() {

      this.isDisable = true
      setTimeout(() => {
        this.isDisable = false
      }, 1000)

      if (this.dialogText.dialogTitle === "添加监考老师") {
        // console.log("调整监考");
        const { data: res } = await addGroupTeacher({
          ids: this.ids.join(","),
          groupId: this.$route.query.id,
        });
        // console.log(res);
        if (res.code !== 200) return this.$message.error(res.message);
        
        this.$message.success(res.data);

      } else if (this.dialogText.dialogTitle === "添加指定考生") {
        // console.log("添加指定考生");
        const { data: res } = await addGroupStudent({
          ids: this.ids.join(","),
          groupId: this.$route.query.id,
        });
        // console.log(res);
        if (res.code !== 200) return this.$message.error(res.message);
        this.$message.success(res.message);

      } else if (this.dialogText.dialogTitle === "批量新增") {

        // console.log("批量新增监控组");
        const { data: res } = await addGroupBatch(
          this.dialogForm,
          this.ids.join(",")
        );
        // console.log(res);
        if (res.code !== 200) return this.$message.error(res.message);
        this.$message.success(res.message);
      }

      this.cancelBtn();
    },
  },
  watch: {
    dialogVisibleFu() {
      this.dialogVisible = this.dialogVisibleFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/dialog";

</style>
